/*------------------------------------*\
  Elements > Page
\*------------------------------------*/

html, body {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  color: $text-color;
  background-color: $body-bg;
}

body {
  overflow-x: auto;
  min-width: 1000px;
}


