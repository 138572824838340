// *----------------------------------*\
//  Settings > Colors
// *----------------------------------*/

$colors: (
  "white": #fff,
  "blue": #41b6e6,
  "blue-dark": #34657f,
  "blue-sap": #0a76d0,
  "pink-light": #ff7285,
  "pink": #ef60a3,
  "pink-dark": #a54570,
  "pink-vtex": #ff3366,
  "green": #00ab8e,
  "green-dark": #006d68,
  "green-lime": #78CA3C,
  "purple": #62499d,
  "purple-dark": #3c1053,
  "brown": #a08629,
  "brown-dark": #924c2e,
  "yellow": #fedd00,
  "orange": #f16423,
  "red": #ce0000,
  "black": #000,
  "gray-lightest": #f4f4f4,
  "gray-lightest2": #f9f9f9,
  "gray-light-blue": #dadee8,
  "gray-light-blue2": #d1d8f5,
  "gray-light": #f0f0f0,
  "gray": #b8b8b8,
  "gray-medium": #a1a1a1,
  "gray-dark": #777777,
  "gray-darkest": #686868
) !default;

$theme-colors: (
  primary: map-get($colors, "purple"),
  secondary: map-get($colors, "yellow"),
  tertiary: map-get($colors, "orange"),
  success: map-get($colors, "purple"),
  info: map-get($colors, "blue"),
  warning: map-get($colors, "orange"),
  danger: map-get($colors, "red")
) !default;

$body-bg: map-get($colors, "gray-lightest");
$error-color: #ff4646;
$highlight-color: #f7f7f7;
$text-color: #686868;
$text-dark-color: #686868;

$all-colors: map-merge(
  $colors,
  map-merge(
    $theme-colors,
    (
      "error": $error-color,
      "highlight": $highlight-color
    )
  )
);

// *----------------------------------*\
//  Settings > Deprecated
// *----------------------------------*/

$text-colors: (
  "primary": #686868,
  "secondary": #a9abad,
  "dark": #464646
) !default;

$c-primary: map-get($theme-colors, "primary");
$c-secondary: map-get($theme-colors, "secondary");
$c-tertiary: map-get($theme-colors, "tertiary");
$c-danger: map-get($theme-colors, "danger");

$c-blue: map-get($colors, "blue");
$c-blue-dark: map-get($colors, "blue-dark");
$c-pink: map-get($colors, "pink");
$c-pink-dark: map-get($colors, "pink-dark");
$c-green: map-get($colors, "green");
$c-green-dark: map-get($colors, "green-dark");
$c-brown: map-get($colors, "brown");
$c-brown-dark: map-get($colors, "brown-dark");
$c-red: map-get($colors, "red");
$c-red-light: map-get($colors, "pink-light");

$c-gray-lightest: map-get($colors, "gray-lightest");
$c-gray-light-blue: map-get($colors, "gray-light-blue");
$c-gray-light-blue2: map-get($colors, "gray-light-blue2");
$c-gray-light: map-get($colors, "gray-light");
$c-gray: map-get($colors, "default");
$c-gray-medium: map-get($colors, "gray-medium");
$c-gray-dark: map-get($colors, "gray-dark");

$c-text-primary: map-get($text-colors, "primary");
$c-text-secondary: map-get($text-colors, "secondary");
$c-text-dark: map-get($text-colors, "dark");
