$border-radius: (
  "none": 0,
  "2": 2px,
  "4": 4px,
  "6": 6px,
  "rounded": 100%,
);

$border-radius-none : 0;
$border-radius-2 : 2px;
$border-radius-4 : 4px;
$border-radius-6 : 6px;
$border-radius-rounded : 100%;
$border-style: 1px solid;
