table {
  width: 100%;
  font-size: 0.8rem;
  color: $text-color;
  margin-bottom: 0;

  &[fixed-layout] {
    table-layout: fixed;
  }

  &[auto-layout] {
    table-layout: auto;
  }

  th {
    text-align: left;
  }

  th,
  td {
    color: $text-color;

    &:first-of-type {
      padding-left: 24px;
    }

    &:last-of-type {
      padding-right: 24px;
    }
  }

  tr {
    height: 4.5em;
    border-bottom: 1px solid rgba($c-gray-light-blue2, 0.6);

    &.-collapsible {
      cursor: default;
      transition: all 200ms;

      &:hover {
        background-color: rgba($c-gray-light-blue2, 0.3);
      }
    }
  }

  th {
    &:first-child {
      border-top-left-radius: $border-radius-4;
    }

    &:last-child {
      border-top-right-radius: $border-radius-4;
    }
  }

  tr:last-child > td {
    &:first-child {
      border-bottom-left-radius: $border-radius-4;
    }

    &:last-child {
      border-bottom-right-radius: $border-radius-4;
    }
  }

  thead {
    &[highlight] {
      > tr > th,
      > th {
        background-color: #f0f1f2;
        color: #777777;
      }

      > tr {
        border-bottom: none;
      }
    }
  }

  tbody {
    tr:last-child {
      padding-right: 24px;
      border-bottom: none;
    }
  }

  .btn {
    font-size: 1.8em;
  }

  // Modifiers

  &[compact] {
    thead th {
      line-height: 1.25;
      padding-top: 12px;
      padding-bottom: 12px;
    }

    tr {
      height: 3em;
    }
  }
}
