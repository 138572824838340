// *----------------------------------*\
//  Settings
// *----------------------------------*/

@import '1-settings/_border';
@import '1-settings/_breakpoints';
@import '1-settings/_colors';
@import '1-settings/_font-weight';
@import '1-settings/_shadows';
@import '1-settings/_spacing';
@import '1-settings/_typography';
@import '1-settings/_times';

// *----------------------------------*\
//  Tools
// *----------------------------------*/

@import '2-tools/_breakpoints';
@import '2-tools/_colors';
@import '2-tools/_helpers';
@import '2-tools/_shadows';
@import '2-tools/_spacing';

// *----------------------------------*\
//  Generic
// *----------------------------------*/

@import '3-generic/_box-sizing';
@import '3-generic/_normalize';
@import '3-generic/_shared';
@import '3-generic/_scrollbar';
@import '3-generic/_reset';


// *----------------------------------*\
//  Elements
// *----------------------------------*/

@import '4-elements/_links';
@import '4-elements/_page';
@import '4-elements/_type';
@import '4-elements/_table';
@import '4-elements/_p';

// *----------------------------------*\
//  Objects
// *----------------------------------*/

// @import '5-objects/_f-grid';
@import '5-objects/_flexboxgrid';

// *----------------------------------*\
//  Components
// *----------------------------------*/

@import '6-components/_button';
@import '6-components/_table-input';

// *----------------------------------*\
//  Trumps
// *----------------------------------*/

@import '7-trumps/_borders';
@import '7-trumps/_colors';
@import '7-trumps/_font-weight';
@import '7-trumps/_shadows';
@import '7-trumps/_spacing';
@import '7-trumps/_text-transforms';

// *----------------------------------*\
//  Angular CDK
// *----------------------------------*/

@import '@angular/cdk/overlay-prebuilt.css';
